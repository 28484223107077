// App.js
import React from 'react';
import './App.css';

function App() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-black text-white bg-opacity-80">
      <div className="text-center animate__animated animate__fadeIn animate__delay-1s">
        <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4">
          SyncState.xyz
        </h1>
        <div className="flex items-center justify-center">
          <div className="w-64 h-4 bg-gray-600 rounded">
            <div className="h-4 bg-blue-500 rounded animate-incrementalLoading"></div>
          </div>
        </div>
        <p className="text-white max-w-lg text-center py-4">
          SyncState is going to be an advanced data exploration platform
          tailored for the zkSync ecosystem. It will provide real-time insights,
          enhanced visualizations, and a user-friendly interface for tracking
          assets, transactions, and smart contract interactions on zkSync.
          SyncState aims to empower both seasoned and budding DeFi enthusiasts
          by offering customizable dashboards, detailed asset tracking, and
          in-depth analytics, contributing to the transparency and growth of the
          zkSync ecosystem.
        </p>
      </div>
    </div>
  );
}

export default App;
